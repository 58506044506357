.cardStyles {
  max-width: 280px !important;
  min-width: 250px !important;
  width: 100% !important;
  height: 154px !important;
  min-height: 154px !important;
  max-height: 154px !important;
  border-radius: 20px !important;
  border: none !important;
  background-size: cover !important;
  margin: 10px !important;
}

.cardForServices {
  margin: 10px !important;
  max-width: 280px !important;
  min-width: 250px !important;
  height: 154px !important;
  min-height: 154px !important;
  max-height: 154px !important;
  background-color: #ddc9ff !important;
  border-radius: 20px !important;
  border: none !important;
  width: 100% !important;
  background-size: cover !important;
}

.typoGraphyText {
  white-space: nowrap !important;
  overflow: hidden !important;
  font-size: 10px !important;
  color: #ffffff;
  text-overflow: ellipsis !important;
  font-family: "Mona Sans", sans-serif !important;
}

.servicesTypo {
  min-width: 130px !important;
  width: 75% !important;
  max-width: 220px !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: "Mona Sans", sans-serif !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.typographyTextStyles {
  font-size: 12px !important;
  text-decoration: underline !important;
  color: white !important;
  padding: 0px !important;
}

.typoGraphyStylesProducts {
  font-size: 14px !important;
  color: #ffffff;
  text-overflow: ellipsis !important;
  font-weight: 600 !important;
  font-family: "Mona Sans", sans-serif !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.spanStyles {
  font-family: "Mona Sans", sans-serif !important;
  font-size: 12px !important;
  letter-spacing: 0.41px !important;
  color: #e8e8e8 !important;
}

.investmesntStyles {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  font-size: 10px !important;
  color: #e8e8e8;
  font-family: "Mona Sans", sans-serif !important;
}

.dollarText {
  font-size: 10px !important;
  color: #e8e8e8;
  text-overflow: ellipsis !important;
  font-weight: 900 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  font-family: "Mona Sans", sans-serif !important;
}

.gridStyle {
  flex: 1 !important;
  justify-content: end !important;
}

.buttonStyles {
  float: right !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  height: 60% !important;
  background-color: #491cbe !important;
  color: #ffffff !important;
  justify-content: center !important;
  font-family: "Mona Sans", sans-serif !important;
  border-radius: 6px !important;
  margin-top: 8px !important;
}

.serviceCardsuttonStyle {
  width: 100px !important;
  height: 28px !important;
  background-color: #6a3be2 !important;
  box-shadow: 1px 8px 29px #695f9724 !important;
  border-radius: 8px !important;
  color: #ffffff !important;

  opacity: 1 !important;
}

.serviceCardsuttonStyle:hover {
  background-color: #6a3be2 !important;
  box-shadow: none !important;
}

.gridStylesd {
  height: 75px !important;
  background-color: #000000 !important;
  opacity: 0.6 !important;
  width: 220px !important;
  min-width: 150px !important;
  max-width: 280px !important;
  border-radius: 0px 8px 8px 0px !important;
}