.commonSelectField {
  height: 40px;
}
.commonSelectField input::placeholder {
  text-overflow: ellipsis !important;
  color: red !important;
  opacity: 1;
}
.commonSelectField .MuiOutlinedInput-notchedOutline {
  height: 43px !important;
  border-radius: 8px;
}
.commonTextField {
  height: 40px;
  grid-column: span 2;
}
.commonTextField input::placeholder {
  text-overflow: ellipsis !important;
  color: #bbc5d5 !important;
  font-family: "Mona Sans", sans-serif;
  opacity: 1;
}
.textField input::placeholder {
  text-overflow: ellipsis !important;
  color: #bbc5d5 !important;
  font-family: "Mona Sans", sans-serif;
  opacity: 1;
}
.commonTextField .MuiInputBase-root {
  height: 40px !important;
  max-width: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.adduserdropdown {
  height: 40px;
}

.adduserdropdown input::placeholder {
  text-overflow: ellipsis;
  color: red;
  opacity: 1;
}

.adduserdropdown .MuiOutlinedInput-notchedOutline {
  height: 45px;
  border-radius: 8px 0 0 8px;
}

/* .adduserdropdown .MuiOutlinedInput-input {
    background-color: #EAEAEA;
    padding-right: 75px;
    font-size: 13px;
    overflow: inherit;
} */

.adduserdropdown .MuiSelect-select {
  overflow: inherit;
}
.mobileTextField {
  grid-column: span 6;
  height: 40px;
}
.mobileTextField .MuiInputBase-root {
  height: 40px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 8px 8px 0px;
}
.mobileTextField input::placeholder {
  text-overflow: ellipsis !important;
  color: #bbc5d5 !important;
  font-family: "Mona Sans", sans-serif;
  opacity: 1;
}
/* .editInput{
    background-color: #F9F9F9;
    opacity: 1;
} */
.editInput .MuiOutlinedInput-input {
  /* background-color: #f9f9f9; */
  opacity: 1;
}
.dateInputField {
  grid-column: span 2;
  height: 40px;
  /* background-color:'#F9F9F9'; */
}
.dateInputField .input::placeholder {
  text-overflow: ellipsis !important;
  font-family: "Mona Sans", sans-serif;
  color: #bbc5d5 !important;
  opacity: 1;
}
.dateInputField .MuiInputBase-root {
  height: 40px !important;
  max-width: auto;
  /* background: #F9F9F9 0% 0% no-repeat padding-box; */
  border-radius: 8px;
}
.placeholder-color {
  color: #bbc5d5 !important;
}
