/* input::-ms-reveal,
input::-ms-clear {
    display: none;
} */

.GenarateButton {
    width: 130px;
    height: 32px;
    background: #683AE0 !important;
    /* border: 1px solid #767676 !important; */
    border-radius: 6px;

}
.cancelButton{
    width: 130px;
    height: 32px;
    /* UI Properties */
    /* border: 1px solid #767676 !important; */
    border-radius: 6px;
    opacity: 1;
    background-color: transparent !important;
    /* color:#767676 !important; */
    margin-left:15px !important;
}
