.reasonTextd {
  color: red !important;
  text-align: center !important;
}

.modalBoxStyle {
  position: absolute !important;
  top: 45% !important;
  background-color: #693be1 !important;
  left: 45% !important;
  transform: translate(-50%, -50%) !important;
  min-width: 75% !important;
  min-height: 35% !important;
  border-radius: 12px !important;
  margin: 5% !important;
}

.reason_title {
  margin-top: 10px !important;
  padding: 10px !important;
  font-size: 18px !important;
  text-align: center !important;
  color: white !important;
}

.button_box {
  text-align: center !important;
  margin-bottom: 10px !important;
  margin-top: 20 !important;
}
.button_validations {
  margin: 10 !important;
  width: 100px !important;
  height: 35px !important;
  border-radius: 12px !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
}

.cancel_Button {
  margin-left: 5 !important;
  margin-top: 10 !important;
  margin-right: 10 !important;
  margin-bottom: 10 !important;
  width: 100px !important;
  height: 35px !important;
  border-radius: 12px !important;
  background-color: #ffffff !important;
  color: #1db954 !important;
  font-size: 14px !important;
}

.layout_box {
  flex-grow: 1 !important;
  margin-top: 27px !important;
}

.update_button {
  background-color: #6a3bde !important;
  color: white !important;
  width: 100px !important;
  height: 32px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  cursor: pointer;
}
.saveusButton {
  background-color: black !important;
  color: white !important;
  width: 100px !important;
  height: 32px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}

.reject_button {
  height: 32px !important;
  color: white !important;
  background-color: black !important;
  width: 100px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
}

.loading_button {
  background-color: gray !important;
  color: white !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  width: 100px !important;
  height: 32px !important;
}

.backCancel {
  margin-left: 10px !important;
  background-color: #ffffff !important;
  color: #7a86a1 !important;
  width: auto !important;
  height: 32px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}

.divStyles {
  text-align: center !important;
  margin-top: 5px !important;
}
.reject_button:hover,
.button_validations:hover,
.cancel_Button:hover,
.saveusButton:hover,
.update_button:hover {
  background-color: #7b7777 !important;
  color: #ffffff !important;
}

.confirmButtonStyle {
  margin: 10 !important;
  width: 100px !important;
  height: 35px !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
  color: #6a3bde !important;
}

.formBox {
  flex-grow: 1 !important;
  margin-top: 2 !important;
  justify-content: center !important;
  align-items: center !important;
}

.infoText {
  margin: 30px 0px 34px 0px !important;
  font-family: "Mona Sans", sans-serif !important;
}

.boxActionContainer {
  flex-grow: 1 !important;
  margin-top: 20px !important;
  flex-direction: row !important;
}

.resubmitButton {
  background-color: #66cc00 !important;
  color: white !important;
  width: 140px !important;
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 12px !important;
}
.confirmButtonStyle:hover {
  background-color: #7b7777 !important;
  color: #ffffff !important;
}
