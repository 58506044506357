.chatbotBox {
  width: 99.5% !important;
}

.chatbotBox iframe {
  /* height: 85vh !important; */
  height: 90vh !important;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  left: -4px;
  top: -2px;
  /* right: -9px; */
  width: 83.5vw;
  /* bottom: 20px; */
  z-index: 0;
}

.chatbotPop iframe {
  /* height: 85vh !important; */
  height: 88vh !important;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  position: fixed;
  top: 11vh;
  right: 12px;
  /* right: -9px; */
  width: 82vw;
  bottom: -38px;
  /* bottom: 20px; */
  z-index: 0;
}

.chatbotButton {
  width: 99.5% !important;
}

.chatbotButton iframe {
  /* height: 85vh !important; */
  height: 60vh !important;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  position: absolute;
  /* right: 30px; */
  right: 0;
  /* width: 79vw; */
  width: 30vw;
  bottom: 9px;
  /* bottom: 20px; */
  z-index: 999;
}

.chatbotAdminBox iframe {
  height: 80vh !important;
  width: 80vw;
}

.closeIcon {
  /* background: #6a3be0; */
  font-size: 30px;
  position: absolute;
  right: 2%;
  z-index: 9999;
  bottom: 89vh;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  /* background: #6a3be0; */
  font-size: 30px;
  position: absolute;
  right: 6px;
  z-index: 9999;
  bottom: 56vh;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminCloseIcon {
  bottom: 80vh;
}

.countText {
  background: #04050a;
  color: #ffffff;
  min-width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50px;
  position: absolute;
  right: 3px;
  top: 1px;
}