.paperLoading {
  height: 100% !important;
  font-family: "Mona Sans", sans-serif !important;
  padding: 5px !important;
}

.tabsStyles {
  border-color: green !important;
  min-height: 55px !important;
  max-height: 65px !important;
  /*  font-family: "Mona Sans", sans-serif !important; */
  margin-top: -5px !important;
}

.accordianDivStyle {
  flex-direction: column !important;
  width: 100% !important;
}
.inputSearchCSS {
  font-family: "Mona Sans", sans-serif !important;
  height: 30px !important;
  width: 100% !important;
  padding-left: 5px !important;
  padding-bottom: 10px !important;
}

.loadingButtonCSS {
  margin-left: 4 !important;
  margin-top: 20 !important;
  width: 100% !important;
  height: 35px !important;
  border-radius: 8 !important;
  font-size: 14px !important;
}

.applyFiltersButton {
  margin-left: 4 !important;
  margin-top: 20px !important;
  font-family: "Mona Sans", sans-serif !important;
  width: 100% !important;
  height: 32px !important;
  border-radius: 8 !important;
  background-color: #66c824 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.applyFiltersButton:hover {
  background-color: #66c824 !important;
  color: #ffffff !important;
}

.divTabpanelcss {
  margin-top: 20px !important;
  margin-bottom: 155px !important;
}
.boxCss {
  flex-grow: 1 !important;
  display: flex !important;
}

.paperStyleCSS {
  border-radius: 13px !important;
  padding: 5px !important;
}

.divStylePortfolioCSS {
  flex-direction: column !important;
  width: 100% !important;
  margin-left: 15px !important;
}
.formilkBoxStyle {
  border: 1px solid #dddddd !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
  padding-bottom: 15px !important;
  padding-top: 10px !important;
  border-radius: 6px !important;
  background-color: #eeeeee !important;
  margin-top: 5px !important;
}
.whiteSpaceAllign {
  margin-left: 2 !important;
  margin-top: 43 !important;
}

.applyingButtonStyle {
  width: 130px !important;
  height: 35px !important;
  margin-top: 42px !important;
  border-radius: 8 !important;
  font-family: "Mona Sans", sans-serif !important;
  font-size: 14px !important;
}
.applyButtonFilterStyle {
  font-family: "Mona Sans", sans-serif !important;
  text-align: center !important;
  width: auto !important;
  height: 35px !important;
  margin-top: 42px !important;
  border-radius: 8 !important;
  background-color: #66c824 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}
.applyButtonFilterStyle:hover {
  background-color: #66c824 !important;
  color: #ffffff !important;
}
.searchTextCSS {
  margin-bottom: 15px !important;
  margin-top: 5px !important;
  font-size: 14px !important;
  font-family: "Mona Sans", sans-serif !important;
  padding-left: 5px !important;
}
.textInput {
  font-family: "Mona Sans", sans-serif !important;
  height: 30px !important;
  width: 100% !important;
  padding-left: 5px !important;
}

.tabPanelDivStyle {
  margin-bottom: 155px !important;
  margin-left: 5px !important;
}

.paymentAlertModal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: "background.paper" !important;
  border: 1px solid #ffffff !important;
  box-shadow: 24 !important;
  padding: 2 !important;
}

.paymentAlertText {
  font-weight: 600 !important;
  font-family: "Mona Sans", sans-serif !important;
}

.iconsStyle {
  float: right !important;
  font-size: 20px !important;
  cursor: pointer !important;
}

.gridContainerStyle {
  border: 2px solid #a9911b !important;
  color: #a9911b !important;
  padding: 1 !important;
  background: #fff7cc !important;
  border-radius: 2 !important;
  margin-bottom: 1 !important;
}

.typoTextStyle {
  margin-bottom: 1 !important;
  font-family: "Mona Sans", sans-serif !important;
}
.proceedButtonCSS {
  color: white !important;
  width: 128px !important;
  height: 32px !important;
  background: #683ae0 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  font-family: "Mona Sans", sans-serif !important;
  margin: 5px !important;
}
.cancelCSS {
  width: 128px !important;
  height: 32px !important;
  color: #767676 !important;
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  box-shadow: 1px 8px 29px #695f9724 !important;
  border-radius: 6px !important;
  border: 1px solid #767676 !important;
  opacity: 1 !important;
  font-family: "Mona Sans", sans-serif !important;
  margin: "5px" !important;
}
.paperCss {
  padding: "5px" !important;
  background-color: #f5f2f2 !important;
  margin-bottom: 120px !important;
}

.gridImages {
  padding: 15px !important;
  border-radius: 15px !important;
  height: "auto" !important;
  border: "none" !important;
  background-size: cover !important;
}

.typoGraphyStyles {
  font-size: 12px !important;
  padding-top: 5px !important;
  padding-right: 8px !important;
  text-decoration: underline !important;
  color: #000000 !important;
  padding-left: 8px !important;
}

.typoTextStyles {
  white-space: wrap !important;
  color: #0bb600 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 1 !important;
  font-family: "Mona Sans", sans-serif !important;
  overflow: hidden !important;
}
.gridStyles {
  border-radius: 8px !important;
  border: 1px solid black !important;
  background-size: cover !important;
  padding: 15px !important;
}

.textAreaAutoSize {
  width: 92% !important;
  height: 75px !important;
  border-radius: 8px !important;
  /* background: #f9f9f9 !important; */
  border: 1px solid #dddddd !important;
}
.confirmButtons {
  color: white !important;
  width: 100px !important;
  height: 32px !important;
  background: #683ae0 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  font-family: "Mona Sans", sans-serif !important;
  margin: "5px" !important;
}
.cancelButtons {
  width: 100px !important;
  height: 32px !important;
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  box-shadow: 1px 8px 29px #695f9724 !important;
  border-radius: 8px !important;
  border: 1px solid #767676 !important;
  opacity: 1 !important;
  font-family: "Mona Sans", sans-serif !important;
  margin: 5px !important;
}

.purchaseTypoStyle {
  font-weight: 600 !important;
  margin: 0.5px 0.5px 1px 0.5px !important;
  font-family: "Mona Sans", sans-serif !important;
}

.boxStylesCSS {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0.5px solid #bfb9b9 !important;
  border-radius: 6px !important;
  padding: 5px !important;
}

.transactionnTypo {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.transactionTypography {
  font-family: "Mona Sans", sans-serif !important;
  margin-top: -5px !important;
  margin-bottom: 5px !important;
  font-size: 16px !important;
}

.spanText {
  color: #0cc300 !important;
  font-family: "Mona Sans", sans-serif !important;
  font-size: 14px !important;
}

.transactionBoxStyle {
  border: 1px solid #bfb9b9 !important;
  border-radius: 0px !important;
  padding: 6px !important;
  max-height: 122px !important;
  min-height: 122px !important;
}

.transactioTypography {
  font-weight: 600 !important;
  margin-top: 1 !important;
  font-family: "Mona Sans", sans-serif !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.transactionTypographyWithColor {
  color: #171b24 !important;
  font-weight: 600 !important;
  margin-top: 1 !important;
  font-family: "Mona Sans", sans-serif !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.mobileTransactionButton {
  background-color: #000000 !important;
  color: white !important;
  border-radius: 8px !important;
  font-family: "Mona Sans", sans-serif !important;
}

.typoGraphyHeaderStyle {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.spanStyles {
  color: #0cc300 !important;
  font-family: "Mona Sans", sans-serif !important;
}

.bannerStyleCard {
  margin: 5px !important;
  cursor: pointer !important;
  height: 147px !important;
  min-height: 145px !important;
  max-height: 160px !important;
  border-radius: 20px !important;
  border: none !important;
  width: 100% !important;
  background-size: cover !important;
}

.gridStylesBanner {
  height: 60px !important;
  background-color: "rgba(0,0,0, 0.2)";
  /* background-color: #000000 !important;
  opacity: 0.6 !important; */
}

.bannerTypoGraphyText {
  text-align: center !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: "Mona Sans", sans-serif !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.portfolioToogleStyle {
  min-width: auto !important;
  font-family: "Mona Sans", sans-serif !important;
  text-transform: "capitalize" !important;
}

.recommendedDiv {
  flex-direction: column !important;
  width: 100% !important;
}

.divRecommendedStyle {
  margin-top: 20px !important;
  margin-bottom: 155px !important;
}
