.yes-b{
    border:1px solid #693BE1;
    background-color:white;
    color:#693BE1;
    padding:8px 30px;
    border-radius: 7px;
    font-size: 18px;
}

.yes-b:hover{
    background-color: #693BE1;
    color: white;
}

.no-b{
    border:1px solid #693BE1;
    padding:8px 30px;
    border-radius: 7px;
    color:#693BE1;
    background-color: transparent;
    font-size: 18px;
}

.no-b:hover{
    background-color: #693BE1;
    color: white;
}
