@font-face {
    font-family: "Futura Md BT";
    src:
        local("Futura Md BT"),
        url(./fonts/futura/futura\ medium\ bt.ttf) format("truetype");
}

@font-face {
    font-family: "Futura BK BT";
    src:
        local("Futura BK BT"),
        url(./fonts/futura/Futura\ Extra\ Black\ font.ttf) format("truetype");
}

@font-face {
    font-family: "Futura Book";
    src:
        local("Futura Book"),
        url(./fonts/futura/Futura\ Book\ font.ttf) format("truetype");
}

@font-face {
    font-family: "The Seasons";
    src:
        local("The Seasons"),
        url(./fonts/Demo_Fonts/Fontspring-DEMO-theseasons-reg.otf) format("opentype");
}

@font-face {
    font-family: "Mona Sans";
    src:
        local("Mona Sans"),
        url(./fonts/monaSans/Mona-Sans.woff2) format("woff2 supports variations"),
        url(./fonts/monaSans/Mona-Sans.woff2) format("woff2-variations");
    font-weight: 200 900;
    font-stretch: 100%;
}

body {
    font-family: "Mona Sans", Arial, sans-serif !important;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    display: flex;
    position: relative;
    font-family: "Mona Sans", Arial, sans-serif !important;
}

.content {
    height: 100%;
    width: 100%;
}

.blinkingcursor {
    display: inline-block;
    width: 1ch;
    animation: flicker 0.5s infinite;
    margin-bottom: 4px;
}

@keyframes flicker {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#main-content-grid {
    padding: 0;
    margin: 0;
}

.svg-icon path {
    stroke: #1e1e1e;
}

#user-profile--circle path {
    stroke-width: 0.5;
}

iframe {
    border: none
}

.report-style-class {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100% !important;
}